const { NODE_ENV } = process.env
const displayPage = NODE_ENV !== "production"

export default (origin) => ({
  title: "Anova Precision™ Oven Recipes",
  description:
    "Explore recipes created for the Anova Precision™ Oven. Learn how to cook sous vide favorites, traditional classics, and discover new steam cooking techniques only available in the APO.",
  openGraph: {
    type: "website",
    locale: "en_US",
    url: `${origin}`,
    title: "Anova Precision™ Oven Recipes",
    description:
      "Explore recipes created for the Anova Precision™ Oven. Learn how to cook sous vide favorites, traditional classics, and discover new steam cooking techniques only available in the APO.",
    images: [
      {
        url: `${origin}/anova-oven-recipes-preview.jpg`,
        width: 1200,
        height: 1200,
        alt: "Anova Precision™ Oven Recipes",
      },
    ],
    site_name: "Anova Precision™ Oven Recipes",
  },
  twitter: {
    cardType: "summary",
    site: "@AnovaCulinary",
  },
  titleTemplate: "%s | Combi Steam Oven Recipes",
  noindex: displayPage,
  nofollow: displayPage,
})
