import "react-multi-carousel/lib/styles.css"
import "~/config/firebaseClient"

import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"
import { NextSeo } from "next-seo"
import DEFAULT_SEO from "next.seo"
import React, { useEffect } from "react"

import { theme } from "~/theme"

const MyApp = ({ Component, pageProps, err }) => {
  const origin = process.env.NEXT_PUBLIC_ORIGIN

  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <React.Fragment>
      <NextSeo {...DEFAULT_SEO(origin)} />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} err={err} />
      </ThemeProvider>
    </React.Fragment>
  )
}

export default MyApp
