import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

export const theme = responsiveFontSizes(
  createMuiTheme({
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
    },
    palette: {
      primary: {
        light: "#ff8b01",
        main: "#fa5b3a",
        dark: "#993427",
      },
      secondary: {
        main: "#508fde",
      },
      text: {
        primary: "#646464",
        secondary: "#2b2b2b",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
      common: {
        white: "#ffffff",
        black: "#000000",
      },
      background: {
        paper: "#ffffff",
        default: "#F7F7F7",
      },
      action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.04)",
        hoverOpacity: 0.04,
        selected: "rgba(0, 0, 0, 0.08)",
        selectedOpacity: 0.08,
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
      divider: "rgba(0, 0, 0, 0.12)",
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      // fontFamily:
      //   "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      fontSize: 14,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      // LargeTitle
      h1: {
        fontWeight: 400,
        fontSize: "2.125rem",
        lineHeight: 1.21,
        letterSpacing: "0.023125rem",
      },
      // Title1
      h2: {
        fontWeight: 400,
        fontSize: "1.75rem",
        lineHeight: 1.21,
        letterSpacing: "0.0225rem",
      },
      // Title2
      h3: {
        fontWeight: 400,
        fontSize: "1.375rem",
        lineHeight: 1.27,
        letterSpacing: "0.021875rem",
      },
      // Title3
      h4: {
        fontWeight: 400,
        fontSize: "1.25rem",
        lineHeight: 1.2,
        letterSpacing: "0.02375rem",
      },
      // Headline
      subtitle1: {
        fontWeight: 600,
        fontSize: "1.0625rem",
        lineHeight: 1.29,
        letterSpacing: "-0.025625rem",
      },
      // Subheadline
      subtitle2: {
        fontWeight: 400,
        fontSize: "0.9375rem",
        lineHeight: 1.33,
        letterSpacing: "-0.015rem",
      },
      // Body
      body1: {
        fontWeight: 400,
        fontSize: "1.0625rem",
        lineHeight: 1.29,
        letterSpacing: "-0.025625em",
      },
      // Callout
      body2: {
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: 1.31,
        letterSpacing: "-0.02rem",
      },
      // Footnote
      overline: {
        fontWeight: 400,
        fontSize: "0.8125rem",
        lineHeight: 1.38,
        letterSpacing: "-0.005rem",
      },
      // Caption1
      caption: {
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.33,
        letterSpacing: "normal",
      },
    },
    shadows: [
      "none",
      "0 1px 4px 0 rgba(157, 157, 157, 0.5)",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
    ],
  }),
)
